import { isProd } from 'util/env'

// TODO: There's probably a better way to do this to handle test / prod environments

export const kPricePackages = [
  {
    id: isProd()
      ? 'price_1MujZRKLGprJPbftngVuA8oy'
      : 'price_1MukAiKLGprJPbftbYeIFHGy',
    credits: 25,
    price: 1.99,
  },
  {
    id: isProd()
      ? 'price_1MuhrlKLGprJPbftqFHPvRYH'
      : 'price_1MullUKLGprJPbftv6SVZugA',
    credits: 50,
    price: 2.99,
  },
  {
    id: isProd()
      ? 'price_1MujazKLGprJPbftGJDAkLPd'
      : 'price_1MulmSKLGprJPbft64HXG8T1',
    credits: 100,
    price: 4.99,
  },
]

export const kCouponCodes: Record<string, number> = {
  rosebud: 50,
}
