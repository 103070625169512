import Dexie from 'dexie'
import type { Table } from 'dexie'
import { User } from 'types/User'
import { Chat } from 'types/Chat'
import { Message } from 'types/Message'

// Include a mock indexedDB for server-side rendering
if (typeof window == 'undefined') {
  // This is an experimental feature dependant, top level await, seem to be working tho
  const fakeIndexedDB = import('fake-indexeddb') as any
  Dexie.dependencies.indexedDB = await fakeIndexedDB
}

export class Database extends Dexie {
  chats!: Table<Chat>
  messages!: Table<Message>
  user!: Table<User>

  constructor() {
    super('innermuse')

    this.version(1).stores({
      chats: '++id, date, day',
      messages: '++id, chatId, topic',
      user: '++id',
    })
    this.version(2).stores({
      chats: '++id, lastMessageAt, topic',
      messages: '++id, chatId, topic',
      user: '++id',
    })
  }
}

export const db = new Database()
