import { IncomingMessage } from 'http'
import isoFetch from 'isomorphic-unfetch'
import { ApiResponse } from 'types/Api'
import fetchBuilder from 'fetch-retry'

const fetch = fetchBuilder(isoFetch)
const retryDelay = 1000
const retries = 3

function getNextApiUrl(path: string, req?: IncomingMessage) {
  if (req && typeof window === 'undefined') {
    // this is running server-side, so we need an absolute URL
    const host = req.headers.host
    if (host?.startsWith('localhost')) {
      return `http://localhost:3000${path}`
    }
    return `https://${host}${path}`
  }
  // this is running client-side, so a relative path is fine
  return path
}

/**
 * Make a request to a Next.js API route. See /pages/api/
 * @param options { method, body, etc. }
 * @param req If this api request is originating from the server side
 */
export async function fetchNextApi<T>(
  path: string,
  options: RequestInit = {},
  req?: IncomingMessage
): Promise<ApiResponse<T>> {
  const url = getNextApiUrl(path, req)
  const resp = await fetch(url, {
    ...options,
    retries,
    retryDelay,
    retryOn: (
      attempt: number,
      error: Error | null,
      response: Response | null
    ) => {
      // Conditional for retrying for every request, the most impactful one here is !response.ok
      if ((Boolean(error) || !response || !response.ok) && attempt < retries) {
        console.error(`Retrying, attempt number ${attempt + 1}`)
        return true
      }
      return false
    },
  })
  return resp.json()
}
