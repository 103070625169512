import mixpanel, { Dict } from 'mixpanel-browser'
import { User } from 'types/User'
import { isDev } from 'util/env'
import ReactGA from 'react-ga4'
import { captureException as sentryCaptureException } from '@sentry/nextjs'
import getConfig from 'next/config'

let INITIALIZED = false

const { publicRuntimeConfig } = getConfig()

export namespace Analytics {
  export const init = () => {
    if (!INITIALIZED) {
      if (process.env.GA_TRACKING_ID) {
        ReactGA.initialize(process.env.GA_TRACKING_ID, {
          gtagUrl: 'https://www.googletagmanager.com/gtag/js',
        })

        ReactGA.gtag('config', 'AW-11111998193')

        ReactGA.gtag(
          'set',
          'appVersion',
          publicRuntimeConfig?.version || 'unknown'
        )
      } else {
        console.debug(
          '%c[Analytics] %c%s',
          'color:green',
          'color:red',
          'GA tracking not setup.'
        )
      }

      if (process.env.MIXPANEL_TOKEN) {
        mixpanel.init(process.env.MIXPANEL_TOKEN, {
          cross_site_cookie: true,
          api_host: '/mp',
          ignore_dnt: true,
        })
      }

      INITIALIZED = true
    }
  }

  export const identify = (userId: string) => {
    if (userId) {
      ReactGA.gtag('set', 'user_properties', {
        crm_id: userId,
      })
      console.debug('Identifying user', userId)
      mixpanel.identify(userId.toString())
    }
  }

  export const reset = () => {
    mixpanel.reset()
  }

  export const timeEvent = (eventName: string) => {
    mixpanel.time_event(eventName)
  }

  export const trackEvent = (eventName: string, eventProps?: Dict) => {
    if (isDev()) {
      console.info(
        '%c[Analytics] %c%s',
        'color:green',
        'color:orange',
        eventName,
        eventProps
      )
    }

    try {
      mixpanel.track(eventName, eventProps)
    } catch (e) {
      sentryCaptureException(e)
      console.error('Unable to log Analytics event', e)

      try {
        mixpanel.track('mixpanel.error', {
          error_message: e.message,
          error: e,
        })
      } catch (err) {
        console.error('Failed to track failed mixpanel event', err)
        sentryCaptureException(err)
      }
    }

    try {
      const gaEventName = eventName.split('.')
      ReactGA.event(gaEventName.join('_'))

      if (
        gaEventName.length > 1 &&
        gaEventName[gaEventName.length - 1] === 'view'
      ) {
        ReactGA.send({
          hitType: 'pageview',
          page: `/view/${gaEventName.slice(1).join('_')}`,
        })
      }
    } catch (error) {
      sentryCaptureException(error)
    }
  }

  export const trackGAConversionEvent = (
    paymentValue: number,
    transactionId: string
  ) => {
    const conversionId = 'AW-11111998193'
    const conversionLabel = 'namKCPCj85gYEPHFzrIp'

    ReactGA.gtag('event', 'conversion', {
      send_to: `${conversionId}/${conversionLabel}`,
      value: paymentValue,
      currency: 'USD',
      transaction_id: transactionId,
    })
  }

  export const incrementUserProp = (propName: string, amount = 1) => {
    mixpanel.people.increment(propName, amount)
  }

  export const trackUserCharge = (amount: number, props?: Dict) => {
    mixpanel.people.track_charge(amount, props)
    Analytics.incrementUserProp('Lifetime Revenue', amount)
  }

  export const setUserPropsFromUser = (user?: User | null) => {
    if (!user?.uuid) {
      return
    }

    let utmParams = {}
    if (window.location.search.includes('utm')) {
      utmParams = Object.fromEntries(
        window.location.search
          .replace('?', '')
          .split('&')
          .filter((param) => param.startsWith('utm_'))
          .map((param) => param.split('='))
      )
    }

    mixpanel.people.set({
      Locale: user.locale,
      Timezone: user.timezone,
      ...utmParams,
    })
  }

  export const setUserProps = (props: Dict) => {
    mixpanel.people.set(props)
  }

  export const setSuperProps = (props: Dict) => {
    mixpanel.register(props)
  }

  export const setSuperPropsOnce = (props: Dict) => {
    mixpanel.register_once(props)
  }
}

export namespace AnalyticsProps {}

export default Analytics
