import NextHead from 'next/head'

export const kDefaultProps = {
  title: 'On-demand AI counseling | Available 24/7 | Affordable',
  description:
    'Instantly access AI-powered guidance and support without breaking the bank. No commitment required.',
  imageUrl: '/images/og/default.jpg',
}

type Props = {
  title?: string
  description?: string
  imageUrl?: string
}

const GlobalHead = () => {
  return (
    <>
      <NextHead>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover'
        />

        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <meta name='apple-mobile-web-app-title' content='InnerMuse' />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />

        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />

        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#fd93a7' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='theme-color' content='#ffffff' />
      </NextHead>
    </>
  )
}

export const MetaHead = ({ title, description, imageUrl }: Props) => {
  title = (title ?? kDefaultProps.title) + ' | InnerMuse'
  description = description ?? kDefaultProps.description
  imageUrl = imageUrl ?? kDefaultProps.imageUrl

  return (
    <NextHead>
      <title>{title}</title>

      {/* Open Graph  */}
      <meta property='og:title' content={title} />
      <meta key='og:type' property='og:type' content='website' />

      {/* Description is required for SEO  */}
      <meta property='og:description' content={description} />
      <meta key='description' name='description' content={description} />
      <meta
        key='twitter:description'
        name='twitter:description'
        content={description}
      />

      {/* Image  */}
      <meta property='og:image' content={imageUrl} />
      <meta
        key='twitter:card'
        name='twitter:card'
        content='summary_large_image'
      />
      <meta key='twitter:image' name='twitter:image' content={imageUrl} />
    </NextHead>
  )
}

export default GlobalHead
