const colors = {
  red: {
    500: '#EE584E',
  },
  brand: {
    300: '#ff7a93',
    400: '#fa6984',
    500: '#E03D78',
    600: '#D93B74',
  },
  brandGray: {
    100: '#F9F3F3',
    200: '#EDE4E4',
    300: '#DED5D5',
    400: '#CCBEBE',
    500: '#8B807F',
    600: '#7F7676',
    700: '#5C5555',
    800: '#332F2',
    900: '#0F0E0E',
  },
  gray: {
    1100: '#170E12',
    1000: '#131313',
    900: '#1C1C1C',
    800: '#2C2C2C',
    700: '#3E3E3E',
    600: '#535353',
    500: '#686868',
    400: '#898989',
    300: '#A6A6A6',
    200: '#E7E7E7',
    100: '#F0F0F0',
  },
  black: {
    1000: '#180E1B',
    900: '#2B2C2D',
    800: '#313233',
  },
  yellow: {
    100: '#FFFBEB',
    200: '#FFF9D8',
    300: '#FEF3C2',
    400: '#E3DEC3',
    500: '#FEE886',
    600: '#FDE05D',
    700: '#FDD835',
    800: '#FDCF08',
    900: '#D4AD02',
  },
  purple: {
    50: '#CBBEEA',
    100: '#BEAFE6',
    200: '#B19FE1',
    300: '#A590DC',
    400: '#8C71D2',
    500: '#7352C8',
    600: '#5D3AB7',
    700: '#4B2F94',
    800: '#311E60',
    900: '#D4AD02',
  },
  green: {
    50: '#F0FFF4',
    100: '#C2E6DB',
    200: '#AAD5C8',
    300: '#93CCBB',
    400: '#7CC4AF',
    500: '#5ABA9D',
    600: '#4FA58B',
    700: '#479980',
    800: '#36846C',
    900: '#235E4D',
  },
}

export default colors
