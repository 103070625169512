import { User } from 'types/User'
import { uuidv4 } from 'util/uuid'
import { getTimezone } from './date'

enum UserNeeds {
  uuid,
  timezone,
}

/**
 * Very early version of a user migration.
 * Returns null if the user was not modified. This way we know if we should
 * update the store or not.
 * @param user
 * @returns
 */
export function migrateUserIfNeeded(user: User): User | null {
  const needs = determineUserNeeds(user)

  if (!needs.length) {
    return null
  }

  for (const need of needs) {
    switch (need) {
      case UserNeeds.timezone:
        user = { ...user, timezone: getTimezone() }
        break
      case UserNeeds.uuid:
        user = { ...user, uuid: uuidv4() }
        break
    }
  }

  return user
}

function determineUserNeeds(user: User): Array<UserNeeds> {
  const needs: Array<UserNeeds> = []

  if (!user.timezone) {
    needs.push(UserNeeds.timezone)
  }

  if (!user.uuid) {
    needs.push(UserNeeds.uuid)
  }

  return needs
}
