import { ChakraProvider } from '@chakra-ui/react'
import theme from 'styles/theme'
import 'styles/globals.css'
import 'styles/dot-animation-style.css'
import Fonts from 'ui/global/Fonts'
import { UserProvider } from 'providers/UserProvider'
import { Analytics } from '@vercel/analytics/react'
import GlobalHead from 'ui/global/Head'
import AppInit from 'ui/global/AppInit'
import { AppProps } from 'next/app'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={theme}>
      <GlobalHead />
      <Fonts />
      <Analytics />
      <UserProvider>
        <AppInit />
        <Component {...pageProps} />
      </UserProvider>
    </ChakraProvider>
  )
}
