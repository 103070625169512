const Button = {
  baseStyle: {
    rounded: 'md',
  },
  variants: {
    primary: {
      background: 'brand.600',
      color: 'white',
      _hover: {
        background: 'brand.500',
        border: 0,
        _disabled: {
          background: 'brand.600',
          opacity: 0.5,
        },
      },
      border: 0,
    },
  },
}

export default Button
