import { fetchNextApi } from 'net/api'

/**
 * Generate a weekly report from the user's entries
 * @param entries
 * @returns
 */
export const createCheckoutSession = (priceId: string, topic?: string) =>
  fetchNextApi<{ sessionId: string }>('/api/payment/new-checkout', {
    method: 'POST',
    body: JSON.stringify({ priceId, topic }),
  })
