import { db } from 'db/db'
import { useLiveQuery } from 'dexie-react-hooks'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { User } from 'types/User'
import { uuidv4 } from 'util/uuid'
import { getTimezone } from 'util/date'
import { migrateUserIfNeeded } from 'util/user'
import { createCheckoutSession } from 'net/payment'
import getStripe from 'util/stripe'
import { kCouponCodes, kPricePackages } from 'constants/payments'
import { useToast } from '@chakra-ui/toast'
import { useRouter } from 'next/router'
import getUserLocale from 'get-user-locale'
import { getNearestLanguage } from 'l10n/languages'
import Analytics from 'lib/analytics'
import {
  kCannedRepliesEnabled,
  kDefaultCredits,
  kDefaultModel,
} from 'constants/defaults'

type UserProviderContextType = {
  user: User | null
  updateUser: (user: Partial<User>) => Promise<void>
  spendCredit: (amount: number) => void
  initiateCheckout: (priceId: string, topic?: string) => void
  redeemPurchase: (purchaseId: string) => void
  redeemCoupon: (message: string) => Promise<boolean>
  addCredits: (amount: number) => void
}

const defaultUserContext = {
  user: null,
}

export const UserProviderContext = createContext<UserProviderContextType>(
  defaultUserContext as UserProviderContextType
)

/**
 * React hook that reads from `UserProvider` context
 * Returns modal disclosure control for generalized modals
 */
export const useUserProvider = () => {
  const context = useContext(UserProviderContext)
  if (context === undefined) {
    throw new Error('useUserProvider must be used within a UserProvider')
  }
  return context
}

type Props = {
  children: ReactNode
}

export function UserProvider({ children }: Props) {
  const dbUser = useLiveQuery(() => db.user.toArray())
  const toast = useToast()
  const router = useRouter()

  const { payment_id: stripeSessionId } = router.query

  const user = useMemo(() => {
    if (dbUser?.length) {
      return dbUser[0] as User
    }

    return null
  }, [dbUser])

  const updateUser = useCallback(
    async (modified: Partial<User>) => {
      if (user?.id == null) {
        return
      }

      await db.user.update(user.id, modified)
    },
    [user?.id]
  )

  /**
   * We will use this effect to migrate the user, if necessary.
   */
  useEffect(() => {
    if (dbUser?.length) {
      const changedUser: User | null = migrateUserIfNeeded(dbUser[0])
      if (changedUser) {
        updateUser(changedUser).then(() => {
          console.debug('[USER] Updated')
        })
      }
    } else if (dbUser && !dbUser.length) {
      Analytics.setUserProps({
        'Starting Credits': kDefaultCredits,
        'Starting Model': kDefaultModel,
        'Canned Replies Enabled': kCannedRepliesEnabled,
      })
      db.user
        .add({
          verified: false,
          uuid: uuidv4(),
          timezone: getTimezone(),
          subscription: {
            credits: kDefaultCredits,
            purchases: [],
          },
          flags: {
            enableCannedReplies: kCannedRepliesEnabled,
          },
          locale: getNearestLanguage(getUserLocale()).code,
        })
        .then(() => {
          console.debug('[USER] Created')
        })
    }
  }, [dbUser, updateUser])

  /**
   * Initiate a Stripe checkout session
   * @param priceId
   * @returns
   * @see https://stripe.com/docs/payments/checkout/one-time
   *  */
  const initiateCheckout = useCallback(
    async (priceId: string, topic?: string) => {
      if (user?.id == null) {
        return
      }
      createCheckoutSession(priceId, topic).then(({ response }) => {
        getStripe().then((stripe) => {
          if (response) {
            const { sessionId } = response

            updateUser({
              subscription: {
                ...user.subscription,
                purchases: [
                  ...user.subscription.purchases,
                  {
                    priceId,
                    sessionId,
                    status: 'pending',
                    timestamp: Date.now(),
                    redeemed: false,
                  },
                ],
              },
            })

            stripe?.redirectToCheckout({ sessionId })
          }
        })
      })
    },
    [updateUser, user]
  )

  /**
   * Spend a credit
   */
  const spendCredit = useCallback(
    (amount: number) => {
      if (user?.id == null) {
        return
      }
      if (user.subscription.credits < amount) {
        return
      }
      updateUser({
        subscription: {
          ...user.subscription,
          credits: user.subscription.credits - amount,
        },
      })
      Analytics.incrementUserProp('Credits Spent', amount)
    },
    [user, updateUser]
  )

  const addCredits = useCallback(
    (amount: number, coupon?: string) => {
      if (user?.id == null) {
        return
      }
      const updatedUser: Partial<User> = {
        subscription: {
          ...user.subscription,
          credits: user.subscription.credits + amount,
        },
      }
      if (coupon) {
        updatedUser.subscription!.couponsUsed = [
          ...(user.subscription.couponsUsed ?? []),
          coupon,
        ]
      }
      updateUser(updatedUser)
      toast({
        position: 'top',
        title: `${amount} credits added!`,
        description: coupon ? `Credit code "${coupon}" applied` : '',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    [user?.id, user?.subscription, updateUser, toast]
  )

  /**
   * Redeem payment when necessary
   */
  useEffect(() => {
    if (stripeSessionId && user) {
      redeemPurchase(stripeSessionId as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeSessionId, user])

  /**
   * Redeem a purchase
   */
  const redeemPurchase = useCallback(
    (sessionId: string) => {
      if (user?.id == null) {
        return
      }
      const purchase = user?.subscription.purchases.find(
        (purchase) => purchase.sessionId === sessionId
      )
      if (purchase && !purchase.redeemed) {
        const price = kPricePackages.find(
          (price) => price.id === purchase.priceId
        )
        if (price) {
          updateUser({
            subscription: {
              ...user.subscription,
              credits: user.subscription.credits + price.credits,
              purchases: user.subscription.purchases.map((purchase) => {
                if (purchase.sessionId === sessionId) {
                  return {
                    ...purchase,
                    redeemed: true,
                    status: 'paid',
                  }
                }
                return purchase
              }),
            },
          }).then(() => {
            Analytics.trackGAConversionEvent(price.price, sessionId)
            Analytics.trackEvent('payments.credit.buy.success', {
              credits: price.credits,
              price: price.price,
              priceId: price.id,
            })
            Analytics.trackUserCharge(price.price, { priceId: price.id })
            Analytics.incrementUserProp('Credits Bought', price.credits)
            toast({
              position: 'top',
              title: `${price.credits} credits added!`,
              description: 'Thank you for your purchase',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            router.replace(router.asPath.split('?')[0])
          })
        }
      }
    },
    [user, updateUser, toast, router]
  )

  /**
   * Redeem a coupon code
   */
  const redeemCoupon = useCallback(
    async (code: string): Promise<boolean> => {
      if (!user) {
        return false
      }

      if (kCouponCodes[code]) {
        if (user.subscription.couponsUsed?.includes(code)) {
          toast({
            position: 'top',
            title: 'Credit code already used',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return false
        }
        const credits = kCouponCodes[code]
        addCredits(credits, code)
        Analytics.trackEvent('payments.coupon.redeem', {
          code,
          credits,
        })
        return true
      }

      return false
    },
    [addCredits, user]
  )

  const context = useMemo(
    () => ({
      user,
      updateUser,
      spendCredit,
      initiateCheckout,
      redeemPurchase,
      redeemCoupon,
      addCredits,
    }),
    [
      user,
      updateUser,
      spendCredit,
      initiateCheckout,
      redeemPurchase,
      redeemCoupon,
      addCredits,
    ]
  )

  return (
    <UserProviderContext.Provider value={context}>
      {children}
    </UserProviderContext.Provider>
  )
}

/**
 * We only care about some modifications here, specifically to prevent
 * an update loop. (Also to not import unnecessary packages)
 * @param user
 * @param previous
 * @returns
 */
export function isModified(user: User, previous: User) {
  if (user.timezone !== previous.timezone) {
    return true
  }

  return false
}
