import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import Accordion from './components/Accordion'
import Button from './components/Button'
import Link from './components/Link'
import Input from './components/Input'
import styles from './styles'

export const breakpoints = {
  xs: 320,
  sm: 430,
  md: 768,
  lg: 960,
  xl: 1200,
  '2xl': 1536,
  xxl: 2048,
}

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
  },
  breakpoints,
  colors,
  styles,
  components: {
    Accordion,
    Button,
    Link,
    Input,
  },
})

export default theme
