import moment from 'moment'

export const Hours = Array.from(Array(24)).map((_, i) => i)
export const HourLocalFormatter = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
})

/**
 * Formats an hour as a local time string (e.g. 9pm or 21:00)
 */

export function hourAsLocalTimeString(hour: number): string {
  return HourLocalFormatter.format(new Date(0, 0, 0, hour, 0, 0, 0))
}

/**
 * @returns true if today is Sunday or if it is Monday before 3am
 */
export const isTodaySunday = (): boolean => {
  const today = moment()
  return (
    moment().isoWeekday() === 7 ||
    (today.isoWeekday() === 1 && today.hour() < 3)
  )
}

/**
 * @returns a date that accounts for the fact the user may be entering data for the previous day
 */

export const getFuzzyDate = (): string => {
  const today = moment()
  if (today.hours() < 3) {
    return today.subtract(1, 'days').format('YYYY-MM-DD')
  } else {
    return today.format('YYYY-MM-DD')
  }
}

/**
 * @returns true if the date is today or yesterday (accounting for the fact the user may be entering data for the previous day)
 * @param dateString
 * @returns
 */
export const isFuzzyToday = (dateString: string): boolean => {
  return (
    moment().subtract('3', 'hours').isSame(moment(dateString), 'day') ||
    moment(dateString).isSame(moment(), 'day')
  )
}
/**
 * Get the nearest UTC hour from a local hour, taking into account DST.
 * Nearest because some UTC offsets are not always 60 minutes.
 * @param localHour
 * @returns
 */
export function getNearestUTCHourFromLocal(localHour: number): number {
  let now = moment()
  now = now.set('hour', localHour)

  if (!now?.isValid()) {
    throw new Error(`Invalid Hour ${localHour}`)
  }

  const utcHour = now.utc().hour()
  return utcHour
}

const DefaultTimezone = 'America/New_York'

/**
 * Get North American Timezone
 */
export function getTimezone() {
  const modifier = moment().isDST() ? 1 : 0
  const utcOffset = moment().utcOffset() - modifier * 60
  const timezone = (() => {
    switch (utcOffset) {
      case -480:
        return 'America/Los_Angeles'
      case -420:
        return 'America/Denver'
      case -360:
        return 'America/Chicago'
      case -300:
        return 'America/New_York'
      default:
        return DefaultTimezone
    }
  })()
  return timezone
}
