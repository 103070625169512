import { useEffect } from 'react'
import Analytics from 'lib/analytics'
import { useUserProvider } from 'providers/UserProvider'
import { isClient, isProd } from 'util/env'
import getConfig from 'next/config'
import LogRocket from 'logrocket'

const { publicRuntimeConfig } = getConfig()

const AppInit = () => {
  const { user } = useUserProvider()

  useEffect(() => {
    if (isClient()) {
      Analytics.init()

      const asyncImportLogRocket = async () => {
        const { default: LogRocket } = await import('logrocket')
        const { default: setupLogRocketReact } = await import('logrocket-react')

        if (process.env.LOGROCKET_CLIENT_ID) {
          LogRocket.init(process.env.LOGROCKET_CLIENT_ID)
        }
        setupLogRocketReact(LogRocket)
        LogRocket.getSessionURL((sessionURL) => {
          Analytics.trackEvent('logrocket.session', { sessionURL })
        })
      }

      if (isProd() && process.env.LOGROCKET_CLIENT_ID) {
        asyncImportLogRocket()
      }
    }
  }, [])

  useEffect(() => {
    if (user?.uuid) {
      Analytics.identify(user.uuid)
      LogRocket.identify(user.uuid)
    }

    if (user) {
      Analytics.setUserPropsFromUser(user)
    }

    Analytics.setSuperProps({
      appVersion: publicRuntimeConfig?.version || 'unknown',
    })
  }, [user])

  return null
}

export default AppInit
