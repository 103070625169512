import { ChatModel } from 'types/Chat'

export const kDefaultCredits = 6
export const kDefaultModel: ChatModel = 'advanced'
export const kCannedRepliesEnabled = false

export const kCreditsPerMessage = {
  basic: 1,
  advanced: 1,
}

export const kCreditsForFeedback = 10
