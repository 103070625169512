export const isLocal = () => process.env.NODE_ENV === 'development'

export const isServer = () => typeof window === 'undefined'

export const isClient = () => typeof window !== 'undefined'

export const isDev = () =>
  process.env.NODE_ENV === 'development' ||
  process.env.APP_ENV === 'development' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'

export const isStaging = () =>
  ['staging', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV || '')

export const isProd = () => process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
