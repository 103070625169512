import { Global } from '@emotion/react'

// This is Chakra recommended way of doing this https://chakra-ui.com/community/recipes/using-fonts#option-2-using-font-face
const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 450;
            src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Book.woff') format('woff');
        }
        @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 500;
            src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Medium.woff') format('woff');
        }
        @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 700;
            src: local('Circular Std'), url('https://fonts.cdnfonts.com/s/15011/CircularStd-Bold.woff') format('woff');
        }
      `}
  />
)

export default Fonts
