import { StyleFunctionProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const styles = {
  global: (props: StyleFunctionProps) => ({
    html: {
      fontSize: '16px',
    },
    body: {
      fontFamily: 'Circular Std, system-ui, sans-serif',
      // For light mode we use light rose color
      background: mode('brandGray.500', 'black')(props),
    },
  }),
}

export default styles
